.App {
  text-align: center;


}


.headline {
  color: "orange"
}
.drawer-button-open{
  color:'grey'
}
.drawer-button-closed{
  color: 'blue'
}
/* Common styles */
/* Common styles */
/* styles.css */
.navbar-container {
  position: absolute;
  flex-direction: column;
  width: 15vw;
  height: fit-content;
  top: 5vh;
  left: 80vw; 
  padding: 10px;
  align-items: center;

  border-radius: 10px;
  background-color: #493633;
  color: #d0d0d0;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.845), -2px -2px 8px rgba(144, 62, 50, 0.559);  /* Outer shadow gives a raised effect */
  transition: width 0.5s ease;
  justify-content: flex-start;
  display: flex; 
}
.rotary-dial {
  position: relative;
  width: 10vh;
  height: 10vh;
  background-color: #333;
  border-radius: 50%;
  box-shadow: inset 0 0 6px rgba(56, 56, 56, 0.929);
  overflow: hidden;
  transition: all ease .2s;

}
.rotary-dial::before {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background-color: #22222279;
  border-radius: 50%;
  box-shadow: inset 1px 1px 10px rgba(186, 186, 186, 0.362);
}

.rotary-dial::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  height: 50%;
  background-color: #525252;
  border-radius: 50%;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}
.rotary-dial-indicator {
  position: relative;
  z-index: 100;
  background-color:#0000001d; 
  box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.5);
  left: 47.5%;
  top: 25%;
  width: .5vh; 
  height: 2vh;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: 5px 0;
  text-decoration: none;
  font-family: sans-serif;
  font-size: 18px;
  color: #333;
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.nav-button:hover {
  background-color: #d0d0d0;
}

.toggle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin: 10px 0;
  background-color: #e0e0e0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #d0d0d0;
}

.content-container{
  background-color: #333;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  min-width: 500px;
  min-height: 300px;
}

/* homeStyles.css */
.audio-player-container{
  position: absolute;
  display: flex;
  width: 14vw;
  height: 25vh;
  top: 60vh;
  left: 80vw; 

  flex-direction: column;
  align-self: center;
  align-items: center;
  background-color: #6b6060;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1);
}

.carousel-container {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  top: 5vh;
  left: 3vw;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
 
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1);
  
  overflow: hidden;

  
}
.gol-container {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  top: 5vh;
  left: 3vw;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
 
  border-radius: 100px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), inset 0 0 10px rgba(0, 0, 0, 0.1);  
  overflow: hidden;

  
}
.osc-container {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  top: 5vh;
  left: 3vw;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
 
  border-radius: 20% 20% 20% 20% /30% 30% 30% 30%;  
  box-shadow: inset 0px 0px 50px #000000, inset 0px 0px 200px #FFFFFF45;
  box-shadow: 0px 0px 100px rgba(0, 0, 0, 0.5);
  
}
.off-container {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  
  flex-direction: column;
  align-items: center;
  background-color: #000000;
 
  border-radius: 100px;
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 1), inset 0 0 1000px rgba(255, 255, 255, .25);
  
  overflow: hidden;

}
.noise-container {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  top: 5vh;
  left: 3vw;
  flex-direction: column;
  align-items: center;
  background-color: #141414;
 
  border-radius: 100px;
  mix-blend-mode: lighten; 
  box-shadow: inset 0 4px 10px rgba(0, 0, 0, 1), inset 0 0 1000px rgba(255, 255, 255, .25);

  overflow: hidden;

}

.cvad-text {
  position: absolute;
  font-family: 'Courier New', Courier, monospace;
  left: 30vw;
  top: 85vh;
  color:rgba(0, 0, 0, 0.25);
  text-shadow: inset 0 4px 10px rgba(0, 0, 0, .5), inset 0 0 1000px rgba(255, 255, 255, .25);


}
.noise-container-overlay {
  position: absolute;
  display: flex;
  width: 75vw;
  height:90vh;
  top: 0vh;
  left: 0vw;
  flex-direction: column;
  align-items: center;
  background-color: "transparent";
 
  border-radius: 100px;
  box-shadow: 10px 10px 100px rgba(255, 255, 255, 1), inset 10px 10px 100px rgb(0, 0, 0,0);
  mix-blend-mode: multiply; 
  overflow: hidden;

}


.carousel-image {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: center center;
  transform: scale(1);
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease-in-out;
}

.carousel-image.visible {
  opacity: 1;
  visibility: visible;
}

.buttonIn { /* Style for the pressed button */
  background-color: #493633;
  border: 1px solid transparent; /* Darker border for a "pressed" look */
  color: white;
  padding: 10px 20px;
  width: 102px;
  height: 52px;
  font-weight: bold;
  box-shadow: inset -2px -2px 8px rgba(120, 75, 68, 0.634), inset 2px 2px 8px rgba(0, 0, 0, 0.912); /* Inset shadow makes it look pressed down */
  text-transform: uppercase;
  border-radius: 4px; /* Slightly rounded corners */
  margin: 10px;
}
.buttonOut { /* Style for the unpressed button */
  background-color: #493633;
  border: 1px solid transparent;
  color: black;
  width: 100px;
  height: 50px;
  padding: 10px 20px;
  font-weight: bold;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.845), -2px -2px 8px rgba(144, 62, 50, 0.559);  /* Outer shadow gives a raised effect */
  text-transform: uppercase;
  border-radius: 4px;
  margin: 10px;
}
.buttonIn:hover, .buttonOut:hover { /* Hover effect for interactivity */
  cursor: pointer;
}
.buttonIn:active, .buttonOut:active { /* Active effect for interactivity */
  background-color: #493633;
  border: 1px solid transparent; /* Darker border for a "pressed" look */
  color: white;
  padding: 10px 20px;
  width: 102px;
  height: 52px;
  font-weight: bold;
  box-shadow: inset -2px -2px 8px rgba(120, 75, 68, 0.634), inset 2px 2px 8px rgba(0, 0, 0, 0.912); /* Inset shadow makes it look pressed down */
  text-transform: uppercase;
  border-radius: 4px; /* Slightly rounded corners */
  margin: 10px;
}
.iconStyle{
  color: grey;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.grid {
  display: grid;
  grid-gap: 5px;

  
}

.cell {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color: 'transparent';
  border: 1px solid #000000;
  box-sizing: border-box;
  transition: border-color 4s linear;
  border-radius: 10px;
}

.cell.alive {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  background-color:'transparent';
  border: 1px solid #00FF00;
  box-sizing: border-box;
  border-radius: 10px; 
  
  
}

.gridPicContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 2fr)); /* Adjust the number of columns as needed */
  gap: 10px; /* Adjust the gap between grid items */
}

.gridPicItem {

  width: 20%;
  height: auto;

}


.channel-readout{
  
  margin: 20px; 
  background-color:#2a2a2a;
  width: 100%;
  height: 40px;
  top: -50%;
  left: -10%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.845), inset -2px -2px 8px rgba(125, 125, 125, 0.559);  /* Outer shadow gives a raised effect */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);


  
}

.channel-readout-on{
  
  margin: 20px; 
  background-color:#313131;
  box-shadow: inset 2px 2px 8px rgba(0, 0, 0, 0.845), inset -2px -2px 8px rgba(125, 125, 125, 0.559);  /* Outer shadow gives a raised effect */
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 40px;
  top: -50%;
  left: -10%;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  display: flex;
 

  
}

.channel-text{
  opacity: 0;
  transition: opacity 1s;
  animation: fade-in-out-animation 20s forwards;
  color:rgb(255, 255, 255);
  font-size: 14pt;
  font-family: "Courier New", Courier, monospace;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.channel-text-small{
  opacity: 0;
  transition: opacity 1s;
  animation: fade-in-out-animation 20s forwards;
  color:rgb(225, 225, 225);
  font-size: 14pt;
  font-family: "Courier New", Courier, monospace;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: right;
}

@keyframes fade-in-out-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}
